<template>
<!-- 위험성평가 시나리오 비교 목록 -->
  <c-table
    ref="table"
    title="위험성평가 시나리오 비교 목록"
    :columns="gridColumns"
    :data="grid.data"
    :editable="false"
    :merge="gridMerge"
    @rowClick="rowClick"
  >
    <template v-slot:customArea="{ props, col }">
      <template v-if="col.name==='customCol'">
        <component
          :is="imprComponent"
          :col="col"
          :props="props"
          :inputEditable="false"
          :requestContentsCols="imprProps.requestContentsCols"
          :tableKey="imprProps.tableKey"
          :ibmTaskTypeCd="imprProps.ibmTaskTypeCd"
          :ibmTaskUnderTypeCd="imprProps.ibmTaskUnderTypeCd"
        />
      </template>
      <template v-else-if="col.name==='ramRiskLevelName'">
        <q-chip :style="`color:${props.row.riskColor};`" outline square>
          {{props.row[col.name]}}
        </q-chip>
      </template>
    </template>
  </c-table>
</template>

<script>
/* eslint-disable */
import selectConfig from '@/js/selectConfig';
import reCheckMixin from '@/pages/ram/reCheckMixin'
export default {
  name: 'compareScenario',
  mixins: [reCheckMixin],
  props: {
  },
  data() {
    return {
      grid: {
        data: [],
      },
      imprComponent: () => import(`${'@/pages/common/ibm/tableImpr.vue'}`),
      charmTypeName: '',
      ramTechniqueCd: '',
      ramRiskAssessmentPlanId: '',
      processCd: '',
      psiDiagramId: '',
      nodeNo: '',
      jobName: '',
      ramCheckItemClassCd: '',
      checkItemName: '',
      editable: true,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    window.addEventListener('beforeunload', this.evtClose);
    this.init();
  },
  beforeUnmount() {
    // window.removeEventListener('beforeunload', this.evtClose);
  },
  beforeDestroy() {
    alert(1)
    opener.g_winPopup = null
  },
  watch: {
  },
  computed: {
    popupParam() {
      return {
        ramTechniqueCd: this.ramTechniqueCd,
        ramRiskAssessmentPlanId: this.ramRiskAssessmentPlanId,
      }
    },
    listUrl() {
      let url = '';
      if (this.ramTechniqueCd === 'RT00000001') {
        // HAZOP 아직..
        url = selectConfig.ram.hazop.compare.url
      } else if (this.ramTechniqueCd === 'RT00000005') {
        // K-PSR
        url = selectConfig.ram.kpsr.compare.url
      } else if (this.ramTechniqueCd === 'RT00000010') {
        // Check-list 아직..
        url = selectConfig.ram.checklist.compare.url
      } else if (this.ramTechniqueCd === 'RT00000015') {
        // JRA
        url = selectConfig.ram.jsa.compare.url
      } else if (this.ramTechniqueCd === 'RT00000020') {
        // KRAS
        url = selectConfig.ram.kras.compare.url
      } else if (this.ramTechniqueCd === 'RT00000025') {
        // 4M
        url = selectConfig.ram.fm.compare.url
      } else if (this.ramTechniqueCd === 'RT00000030') {
        // CHARM 아직..
        if (this.charmTypeName === 'estimation') {
          url = selectConfig.ram.charm.compare.estimation.url
        } else {
          url = selectConfig.ram.charm.compare.result.url
        }
        // url = selectConfig.ram.fm.compare.url
      } else if (this.ramTechniqueCd === 'RT00000035') {
        // 3단계 판단법
        url = selectConfig.ram.third.compare.url
      }
      return url;
    },
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      // code setting
      if (opener) {
        opener.g_winPopup = this;
      }

      let query = window.location.search;
      if (!query) {
        let nativeUrl = window.location.href;
        nativeUrl = nativeUrl.replace('/#', '');

        let url = new URL(nativeUrl);
        const urlParams = url.searchParams;

        this.ramTechniqueCd = urlParams.get('ramTechniqueCd');
        this.charmTypeName = urlParams.get('charmTypeName');
        this.ramRiskAssessmentPlanId = urlParams.get('ramRiskAssessmentPlanId');
        this.processCd = urlParams.get('processCd');
        this.psiDiagramId = urlParams.get('psiDiagramId');
        this.nodeNo = urlParams.get('nodeNo');
        this.jobName = urlParams.get('jobName');
        this.ramCheckItemClassCd = urlParams.get('ramCheckItemClassCd');
        this.checkItemName = urlParams.get('checkItemName');
      } else {
        let param = new URLSearchParams(query);
        if (param) {
          this.ramTechniqueCd = param.get('ramTechniqueCd');
          this.charmTypeName = param.get('charmTypeName');
          this.ramRiskAssessmentPlanId = param.get('ramRiskAssessmentPlanId');
          this.processCd = param.get('processCd');
          this.psiDiagramId = param.get('psiDiagramId');
          this.nodeNo = param.get('nodeNo');
          this.jobName = param.get('jobName');
          this.ramCheckItemClassCd = param.get('ramCheckItemClassCd');
          this.checkItemName = param.get('checkItemName');
        }
      }
      // list setting
      this.getList();
    },
    getList() {
      // 선택된 위험성평가 정보가 있는 경우
      if (this.ramRiskAssessmentPlanId && this.ramRiskAssessmentPlanId) {
        this.$http.url = this.listUrl;
        this.$http.type = 'GET';
        this.$http.param = {
          ramRiskAssessmentPlanId: this.ramRiskAssessmentPlanId,
          processCd: this.processCd,
          psiDiagramId: this.ramAssessNodeId,
          nodeNo: this.nodeNo,
          jobName: this.jobName,
          ramCheckItemClassCd: this.ramCheckItemClassCd,
          checkItemName: this.checkItemName,
        };
        this.$http.request((_result) => {
          this.grid.data = _result.data;
        },);
      }
    },
    rowClick(row) {
      if(!opener) {
        return;
      }

      // let sendStr = JSON.stringify( sendObj );

      // 부모 창에 Message를 보냄
      window.opener.postMessage(row, '*');
    },
    evtClose(e) {
      // e.preventDefault();
      window.opener.postMessage('CLOSE', '*');
      // e.returnValue = '';
      return 'bye';
    }
  }
};
</script>
